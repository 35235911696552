// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import {
  TOGGLE_APPLICATION_ROLLOUT_FILTER,
  TOGGLE_BU_ROLLOUT_FILTER,
} from "../../actions";
import { OVERVIEW_APPLICATIONS } from "../../utils/constants";

// `.markets` can be either an Array of countries or a Number.
//
// Also, we have an array of arrays for every application, each
// element on the outer array is a new line in the rollout
// overview page.
const DATA = Object.entries({
  [OVERVIEW_APPLICATIONS.HOMEPAGE]: [
    [
      {
        businessUnits: ["EXP", "DGF", "FRE", "eCS", "DSC"],
        endDate: new Date(2021, 2, 30),
        id: 1,
        markets: 111,
        nextPhaseId: 2,
        startDate: new Date(2021, 1, 5),
        title: "UDC3 - Preparation",
        type: "Preparation",
      },
      {
        endDate: new Date(2021, 4, 31),
        id: 2,
        startDate: new Date(2021, 2, 31),
        title: "UDC3 - Rollout",
        type: "Rollout",
      },
    ],
  ],
  [OVERVIEW_APPLICATIONS.GAQ]: [
    [
      {
        businessUnits: ["eCS"],
        endDate: new Date(2021, 2, 15),
        id: 1,
        markets: ["PT", "ES"],
        startDate: new Date(2021, 0, 1),
        title: "eCS on-boarding Iberia",
        type: "Rollout",
      },
      {
        businessUnits: ["FRE", "DGF", "EXP"],
        endDate: new Date(2021, 5, 29),
        id: 2,
        markets: [
          "AR",
          "CH",
          "EC",
          "EE",
          "FI",
          "GR",
          "HR",
          "HU",
          "ID",
          "IL",
          "KH",
          "LV",
          "MA",
          "NO",
          "NZ",
          "PH",
          "RO",
          "RS",
          "TW",
        ],
        nextPhaseId: 3,
        startDate: new Date(2021, 3, 5),
        title: "Phase 3 - Preparation",
        type: "Preparation",
      },
      {
        endDate: new Date(2021, 8, 30),
        id: 3,
        startDate: new Date(2021, 6, 1),
        title: "Phase 3 - Rollout",
        type: "Rollout",
      },
    ],
    [
      {
        businessUnits: ["FRE", "DGF", "EXP"],
        endDate: new Date(2021, 8, 30),
        id: 1,
        markets: 9,
        nextPhaseId: 2,
        startDate: new Date(2021, 7, 1),
        title: "Phase 4 - Preparation",
        type: "Preparation",
      },
      {
        endDate: new Date(2021, 10, 30),
        id: 2,
        startDate: new Date(2021, 9, 1),
        title: "Phase 4 - Rollout",
        type: "Rollout",
      },
    ],
    [
      {
        endDate: new Date(2021, 9, 31),
        id: 1,
        markets: ["DE"],
        businessUnits: ["P&P", "DGF", "EXP"],
        startDate: new Date(2021, 8, 15),
        title: "DE Rollout",
        type: "Rollout",
      },
    ],
  ],
  [OVERVIEW_APPLICATIONS.FS]: [
    [
      {
        businessUnits: ["EXP", "DGF", "FRE", "eCS", "DPI"],
        endDate: new Date(2021, 2, 31),
        id: 1,
        markets: [
          "LT",
          "HR",
          "EE",
          "UA",
          "BG",
          "SK",
          "VN",
          "LV",
          "HU",
          "EC",
          "VE",
          "KZ",
          "BO",
          "MA",
          "KW",
          "IL",
          "GH",
          "BH",
          "QA",
          "KE",
          "AE",
          "EG",
          "PH",
          "NG",
        ],
        nextPhaseId: 2,
        startDate: new Date(2021, 0, 1),
        title: "Wave 3 - Preparation",
        type: "Preparation",
      },
      {
        endDate: new Date(2021, 5, 30),
        id: 2,
        startDate: new Date(2021, 3, 1),
        title: "Wave 3 - Rollout",
        type: "Rollout",
      },
    ],
    [
      {
        businessUnits: ["PAR", "EXP", "DGF", "DSC", "FRE", "eCS", "DPI"],
        endDate: new Date(2021, 9, 24),
        id: 1,
        markets: [
          "AE",
          "AR",
          "AT",
          "AU",
          "BE",
          "BG",
          "BH",
          "BO",
          "BR",
          "CA",
          "CH",
          "CL",
          "CN",
          "CO",
          "CZ",
          "DE",
          "DK",
          "EC",
          "EE",
          "EG",
          "ES",
          "FI",
          "FR",
          "GB",
          "GH",
          "GR",
          "HK",
          "HR",
          "HU",
          "IE",
          "IL",
          "IN",
          "IT",
          "JP",
          "KE",
          "KR",
          "KW",
          "KZ",
          "LT",
          "LV",
          "MA",
          "MX",
          "MY",
          "NG",
          "NL",
          "NO",
          "PE",
          "PH",
          "PK",
          "PL",
          "PT",
          "QA",
          "RO",
          "SA",
          "SE",
          "SK",
          "TH",
          "TR",
          "TW",
          "UA",
          "US",
          "VE",
          "VN",
          "ZA",
        ],
        nextPhaseId: 2,
        startDate: new Date(2021, 7, 2),
        title: "FS 2.0 - Preparation",
        type: "Preparation",
      },
      {
        endDate: new Date(2021, 11, 31),
        id: 2,
        startDate: new Date(2021, 9, 25),
        title: "FS 2.0 - Rollout",
        type: "Rollout",
      },
    ],
  ],
}).reduce(
  (acc, [key, allRows]) => ({
    ...acc,
    [key]: allRows.map((currentRow) =>
      currentRow.map((v) => {
        const previousPhase = currentRow.find((c) => c.nextPhaseId === v.id);

        // Dinamically link a phase with the previous phase (if applicable)
        return previousPhase
          ? {
              ...v,
              businessUnits: previousPhase.businessUnits,
              markets: previousPhase.markets,
              previousPhaseId: previousPhase.id,
            }
          : v;
      })
    ),
  }),
  {}
);

const INITIAL_STATE = {
  businessUnits: [],
  data: DATA,
  applications: [
    OVERVIEW_APPLICATIONS.HOMEPAGE,
    OVERVIEW_APPLICATIONS.FS,
    OVERVIEW_APPLICATIONS.GAQ,
  ],
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TOGGLE_APPLICATION_ROLLOUT_FILTER:
      return {
        ...state,
        applications: state.applications.includes(payload)
          ? state.applications.filter((a) => a !== payload)
          : [...state.applications, payload],
      };

    case TOGGLE_BU_ROLLOUT_FILTER:
      return {
        ...state,
        businessUnits: state.businessUnits.includes(payload)
          ? state.businessUnits.filter((a) => a !== payload)
          : [...state.businessUnits, payload],
      };

    default:
      return state;
  }
};

export default reducer;

export const get = identity;
