// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import {
  DELETE_EMAIL_LIST_SUBSCRIBER,
  DELETE_EMAIL_LIST_SUBSCRIBER_FAILURE,
  DELETE_EMAIL_LIST_SUBSCRIBER_SUCCESS,
  RESET_DELETE_EMAIL_LIST_SUBSCRIBER,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";
import * as reducers from "../..";

const deleteSubscriber = () => ({
  payload: {},
  type: DELETE_EMAIL_LIST_SUBSCRIBER,
});

const deleteSubscriberSuccess = (payload) => ({
  payload,
  type: DELETE_EMAIL_LIST_SUBSCRIBER_SUCCESS,
});

const deleteSubscriberFailure = (error) => ({
  error: true,
  payload: error,
  type: DELETE_EMAIL_LIST_SUBSCRIBER_FAILURE,
});

const resetDeleteSubscriber = () => ({
  payload: {},
  type: RESET_DELETE_EMAIL_LIST_SUBSCRIBER,
});

export const deleteSubscriberEffect =
  (email) =>
  (dispatch, getState, { api }) => {
    dispatch(deleteSubscriber());

    const accessToken = RD.withDefault("", reducers.getLoggedUser(getState()));

    api
      .deleteEmailListSubscriber(email, accessToken)
      .then((res) => res.status !== 204 && Promise.reject())
      .then(compose(dispatch, deleteSubscriberSuccess))
      .catch(compose(dispatch, deleteSubscriberFailure));
  };

export const resetDeleteSubscriberEffect = () => (dispatch) => {
  dispatch(resetDeleteSubscriber());
};
