import React from "react";
import { THEME } from "@dhl-official/react-ui-library";
import PropTypes from "prop-types";
import styled from "styled-components";
import Flags from "../../Flags";
import COPY from "../../../assets/copy/index.json";
import { sortByCountry } from "../../../utils";

// #region Component Styles
const Container = styled.div`
  min-width: 200px;
  max-width: 400px;
`;

const MarketList = styled.ul`
  margin-top: ${THEME.getUnit("regular")};
`;

const Market = styled.li`
  display: flex;
  align-items: center;
`;
// #endregion

const TimelinePhaseDetails = ({ phase }) => (
  <Container>
    {phase.type} for {phase.markets.length} markets
    <MarketList>
      {[...phase.markets].sort(sortByCountry()).map((country) => (
        <Market key={country}>
          <Flags country={country} /> {COPY[`Country.${country}`]}
        </Market>
      ))}
    </MarketList>
  </Container>
);

TimelinePhaseDetails.propTypes = {
  phase: PropTypes.object.isRequired,
};

export default TimelinePhaseDetails;
