// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import {
  IMPORT_EMAIL_LIST_SUBSCRIBERS,
  IMPORT_EMAIL_LIST_SUBSCRIBERS_FAILURE,
  IMPORT_EMAIL_LIST_SUBSCRIBERS_SUCCESS,
  RESET_IMPORT_EMAIL_LIST_SUBSCRIBERS,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";

const INITIAL_STATE = RD.notAsked();

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESET_IMPORT_EMAIL_LIST_SUBSCRIBERS:
      return RD.notAsked();

    case IMPORT_EMAIL_LIST_SUBSCRIBERS:
      return RD.loading();

    case IMPORT_EMAIL_LIST_SUBSCRIBERS_SUCCESS:
      return RD.success(payload);

    case IMPORT_EMAIL_LIST_SUBSCRIBERS_FAILURE:
      return RD.error(payload);

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
