// 9fbef606107a605d69c0edbcd8029e5d

export const CLOSE_MODAL = "CLOSE_MODAL";
export const DOWNLOAD_COUNTRY_CONFIGURATION = "DOWNLOAD_COUNTRY_CONFIGURATION";
export const DROP_DRAGGED_BUSINESS_UNIT = "DROP_DRAGGED_BUSINESS_UNIT";
export const FOCUS_ON_RELEASED_VERSION = "FOCUS_ON_RELEASED_VERSION";
export const FOCUS_ON_FSX_RELEASED_VERSION = "FOCUS_ON_FSX_RELEASED_VERSION";

export const GET_BUSINESS_UNITS = "GET_BUSINESS_UNITS";
export const GET_BUSINESS_UNITS_FAILURE = "GET_BUSINESS_UNITS_FAILURE";
export const GET_BUSINESS_UNITS_SUCCESS = "GET_BUSINESS_UNITS_SUCCESS";

export const FETCH_EMAIL_LIST_LISTS = "FETCH_EMAIL_LIST_LISTS";
export const FETCH_EMAIL_LIST_LISTS_FAILURE = "FETCH_EMAIL_LIST_LISTS_FAILURE";
export const FETCH_EMAIL_LIST_LISTS_SUCCESS = "FETCH_EMAIL_LIST_LISTS_SUCCESS";
export const FETCH_EMAIL_LIST_SUBSCRIBERS = "FETCH_EMAIL_LIST_SUBSCRIBERS";
export const FETCH_EMAIL_LIST_SUBSCRIBERS_FAILURE =
  "FETCH_EMAIL_LIST_SUBSCRIBERS_FAILURE";
export const FETCH_EMAIL_LIST_SUBSCRIBERS_SUCCESS =
  "FETCH_EMAIL_LIST_SUBSCRIBERS_SUCCESS";
export const IMPORT_EMAIL_LIST_SUBSCRIBERS = "IMPORT_EMAIL_LIST_SUBSCRIBERS";
export const IMPORT_EMAIL_LIST_SUBSCRIBERS_FAILURE =
  "IMPORT_EMAIL_LIST_SUBSCRIBERS_FAILURE";
export const IMPORT_EMAIL_LIST_SUBSCRIBERS_SUCCESS =
  "IMPORT_EMAIL_LIST_SUBSCRIBERS_SUCCESS";
export const RESET_IMPORT_EMAIL_LIST_SUBSCRIBERS =
  "RESET_IMPORT_EMAIL_LIST_SUBSCRIBERS";
export const DELETE_EMAIL_LIST_SUBSCRIBER = "DELETE_EMAIL_LIST_SUBSCRIBER";
export const DELETE_EMAIL_LIST_SUBSCRIBER_FAILURE =
  "DELETE_EMAIL_LIST_SUBSCRIBER_FAILURE";
export const DELETE_EMAIL_LIST_SUBSCRIBER_SUCCESS =
  "DELETE_EMAIL_LIST_SUBSCRIBER_SUCCESS";
export const RESET_DELETE_EMAIL_LIST_SUBSCRIBER =
  "RESET_DELETE_EMAIL_LIST_SUBSCRIBER";
export const RESET_EDITED_EMAIL_LIST_SUBSCRIBERS =
  "RESET_EDITED_EMAIL_LIST_SUBSCRIBERS";
export const UPDATE_EDITED_EMAIL_LIST_SUBSCRIBERS =
  "UPDATE_EDITED_EMAIL_LIST_SUBSCRIBERS";
export const UPDATE_EMAIL_LIST_SUBSCRIBER = "UPDATE_EMAIL_LIST_SUBSCRIBER";
export const UPDATE_EMAIL_LIST_SUBSCRIBER_FAILURE =
  "UPDATE_EMAIL_LIST_SUBSCRIBER_FAILURE";
export const UPDATE_EMAIL_LIST_SUBSCRIBER_SUCCESS =
  "UPDATE_EMAIL_LIST_SUBSCRIBER_SUCCESS";
export const RESET_UPDATE_EMAIL_LIST_SUBSCRIBER =
  "RESET_UPDATE_EMAIL_LIST_SUBSCRIBER";

export const GET_FS_ANALYTICS = "GET_FS_ANALYTICS";
export const GET_FS_ANALYTICS_FAILURE = "GET_FS_ANALYTICS_FAILURE";
export const GET_FS_ANALYTICS_SUCCESS = "GET_FS_ANALYTICS_SUCCESS";
export const GET_GAQ_ANALYTICS = "GET_GAQ_ANALYTICS";
export const GET_GAQ_ANALYTICS_FAILURE = "GET_GAQ_ANALYTICS_FAILURE";
export const GET_GAQ_ANALYTICS_SUCCESS = "GET_GAQ_ANALYTICS_SUCCESS";

export const GET_FS_COUNTRY_CONFIGURATION = "GET_FS_COUNTRY_CONFIGURATION";
export const GET_FS_COUNTRY_CONFIGURATION_FAILURE =
  "GET_FS_COUNTRY_CONFIGURATION_FAILURE";
export const GET_FS_COUNTRY_CONFIGURATION_SUCCESS =
  "GET_FS_COUNTRY_CONFIGURATION_SUCCESS";

export const GET_FS_COUNTRY_SETTINGS = "GET_FS_COUNTRY_SETTINGS";
export const GET_FS_COUNTRY_SETTINGS_FAILURE =
  "GET_FS_COUNTRY_SETTINGS_FAILURE";
export const GET_FS_COUNTRY_SETTINGS_SUCCESS =
  "GET_FS_COUNTRY_SETTINGS_SUCCESS";

export const GET_FS_CONNECTORS_OVERVIEW = "GET_FS_CONNECTORS_OVERVIEW";
export const GET_FS_CONNECTORS_OVERVIEW_FAILURE =
  "GET_FS_CONNECTORS_OVERVIEW_FAILURE";
export const GET_FS_CONNECTORS_OVERVIEW_SUCCESS =
  "GET_FS_CONNECTORS_OVERVIEW_SUCCESS";

export const GET_GAQ_COUNTRY_SETTINGS = "GET_GAQ_COUNTRY_SETTINGS";
export const GET_GAQ_COUNTRY_SETTINGS_FAILURE =
  "GET_GAQ_COUNTRY_SETTINGS_FAILURE";
export const GET_GAQ_COUNTRY_SETTINGS_SUCCESS =
  "GET_GAQ_COUNTRY_SETTINGS_SUCCESS";

export const FETCH_GAQ_RELEASE_NOTES = "FETCH_GAQ_RELEASE_NOTES";
export const FETCH_GAQ_RELEASE_NOTES_FAILURE =
  "FETCH_GAQ_RELEASE_NOTES_FAILURE";
export const FETCH_GAQ_RELEASE_NOTES_SUCCESS =
  "FETCH_GAQ_RELEASE_NOTES_SUCCESS";

export const RELOAD_GAQ_RELEASE_NOTES = "RELOAD_GAQ_RELEASE_NOTES";
export const RELOAD_GAQ_RELEASE_NOTES_FAILURE =
  "RELOAD_GAQ_RELEASE_NOTES_FAILURE";
export const RELOAD_GAQ_RELEASE_NOTES_SUCCESS =
  "RELOAD_GAQ_RELEASE_NOTES_SUCCESS";

export const GET_GAQ_RELEASE_NOTE = "GET_GAQ_RELEASE_NOTE";
export const GET_GAQ_RELEASE_NOTE_FAILURE = "GET_GAQ_RELEASE_NOTE_FAILURE";
export const GET_GAQ_RELEASE_NOTE_SUCCESS = "GET_GAQ_RELEASE_NOTE_SUCCESS";

export const SAVE_GAQ_RELEASE_NOTE = "SAVE_GAQ_RELEASE_NOTE";
export const SAVE_GAQ_RELEASE_NOTE_SUCCESS = "SAVE_GAQ_RELEASE_NOTE_SUCCESS";
export const SAVE_GAQ_RELEASE_NOTE_FAILURE = "SAVE_GAQ_RELEASE_NOTE_FAILURE";

export const UPDATE_GAQ_RELEASE_NOTE = "UPDATE_GAQ_RELEASE_NOTE";
export const UPDATE_GAQ_RELEASE_NOTE_SUCCESS =
  "UPDATE_GAQ_RELEASE_NOTE_SUCCESS";
export const UPDATE_GAQ_RELEASE_NOTE_FAILURE =
  "UPDATE_GAQ_RELEASE_NOTE_FAILURE";

export const DELETE_GAQ_RELEASE_NOTE = "DELETE_GAQ_RELEASE_NOTE";
export const DELETE_GAQ_RELEASE_NOTE_FAILURE =
  "DELETE_GAQ_RELEASE_NOTE_FAILURE";
export const DELETE_GAQ_RELEASE_NOTE_SUCCESS =
  "DELETE_GAQ_RELEASE_NOTE_SUCCESS";
export const RESET_DELETE_GAQ_RELEASE_NOTE = "RESET_DELETE_GAQ_RELEASE_NOTE";

export const GET_RELEASED_COUNTRIES = "GET_RELEASED_COUNTRIES";
export const GET_RELEASED_COUNTRIES_FAILURE = "GET_RELEASED_COUNTRIES_FAILURE";
export const GET_RELEASED_COUNTRIES_SUCCESS = "GET_RELEASED_COUNTRIES_SUCCESS";
export const OPEN_CJ_MAP_REGION_DATA_MODAL = "OPEN_CJ_MAP_REGION_DATA_MODAL";
export const OPEN_EDIT_BUSINESS_UNIT_MODAL = "OPEN_EDIT_BUSINESS_UNIT_MODAL";

export const OPEN_HOMEPAGE_MAP_REGION_DATA_MODAL =
  "OPEN_HOMEPAGE_MAP_REGION_DATA_MODAL";

export const OPEN_SHARE_MODAL = "OPEN_SHARE_MODAL";

export const OPEN_EMAIL_LIST_DELETE_SUBSCRIBER_MODAL =
  "OPEN_EMAIL_LIST_DELETE_SUBSCRIBER_MODAL";
export const OPEN_EMAIL_LIST_EXPORT_MODAL = "OPEN_EMAIL_LIST_EXPORT_MODAL";
export const OPEN_DELETE_GAQ_RELEASE_NOTE_MODAL =
  "OPEN_DELETE_GAQ_RELEASE_NOTE_MODAL";

export const OPEN_TIMELINE_PHASE_DETAILS_MODAL =
  "OPEN_TIMELINE_PHASE_DETAILS_MODAL";

export const OPEN_PROJECT_OVERVIEW_DETAILS_MODAL =
  "OPEN_PROJECT_OVERVIEW_DETAILS_MODAL";

export const QUERY_FS_COUNTRY = "QUERY_FS_COUNTRY";
export const QUERY_GAQ_COUNTRY = "QUERY_GAQ_COUNTRY";
export const QUERY_GAQ_RELEASE_NOTE_COUNTRY = "QUERY_GAQ_RELEASE_NOTE_COUNTRY";

export const REMOVE_COUNTRY_SETTINGS_BUSINESS_UNIT =
  "REMOVE_COUNTRY_SETTINGS_BUSINESS_UNIT";

export const REMOVE_DRAGGING_BUSINESS_UNIT_DROP_ZONE =
  "REMOVE_DRAGGING_BUSINESS_UNIT_DROP_ZONE";

export const SAVE_COUNTRY_SETTINGS = "SAVE_COUNTRY_SETTINGS";
export const SAVE_COUNTRY_SETTINGS_FAILURE = "SAVE_COUNTRY_SETTINGS_FAILURE";
export const SAVE_COUNTRY_SETTINGS_SUCCESS = "SAVE_COUNTRY_SETTINGS_SUCCESS";
export const SELECT_FS_COUNTRY = "SELECT_FS_COUNTRY";
export const SELECT_GAQ_COUNTRY = "SELECT_GAQ_COUNTRY";
export const SELECT_GAQ_RELEASE_NOTE_COUNTRY =
  "SELECT_GAQ_RELEASE_NOTE_COUNTRY";
export const SET_BUSINESS_UNIT_OPTIONS = "SET_BUSINESS_UNIT_OPTIONS";
export const SET_BUSINESS_UNIT_THRESHOLD = "SET_BUSINESS_UNIT_THRESHOLD";

export const SET_BUSINESS_UNIT_THRESHOLD_ENABLED =
  "SET_BUSINESS_UNIT_THRESHOLD_ENABLED";

export const SET_CJ_MAP_DISPLAY_MODE = "SET_CJ_MAP_DISPLAY_MODE";
export const SET_CJ_MAP_REGION = "SET_CJ_MAP_REGION";

export const SET_CJ_ROLLOUT_APPLICATION_FILTER_EFFECT =
  "SET_CJ_ROLLOUT_APPLICATION_FILTER_EFFECT";

export const SET_FS_COUNTRY = "SET_FS_COUNTRY";
export const SET_COUNTRY_SETTINGS_EDIT_MODE = "SET_COUNTRY_SETTINGS_EDIT_MODE";

export const SET_DRAGGING_BUSINESS_UNIT_DROP_ZONE =
  "SET_DRAGGING_BUSINESS_UNIT_DROP_ZONE";

export const SET_GAQ_COUNTRY = "SET_GAQ_COUNTRY";
export const SET_HOMEPAGE_MAP_DISPLAY_MODE = "SET_HOMEPAGE_MAP_DISPLAY_MODE";
export const SET_HOMEPAGE_MAP_REGION = "SET_HOMEPAGE_MAP_REGION";

export const SET_HOMEPAGE_ROLLOUT_VERSION_FILTER_EFFECT =
  "SET_HOMEPAGE_ROLLOUT_VERSION_FILTER_EFFECT";

export const SET_NEW_COUNTRY_SETTINGS = "SET_NEW_COUNTRY_SETTINGS";
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_OUT = "SIGN_OUT";
export const START_DRAGGING_BUSINESS_UNIT = "START_DRAGGING_BUSINESS_UNIT";

export const TOGGLE_APPLICATION_ROLLOUT_FILTER =
  "TOGGLE_APPLICATION_ROLLOUT_FILTER";

export const TOGGLE_BU_ROLLOUT_FILTER = "TOGGLE_BU_ROLLOUT_FILTER";

export const TOGGLE_CJ_ROLLOUT_STATUS_FILTER =
  "TOGGLE_CJ_ROLLOUT_STATUS_FILTER";

export const TOGGLE_FS_ANALYTICS_BU_FILTER = "TOGGLE_FS_ANALYTICS_BU_FILTER";
export const TOGGLE_GAQ_ANALYTICS_BU_FILTER = "TOGGLE_GAQ_ANALYTICS_BU_FILTER";

export const TOGGLE_HOMEPAGE_ROLLOUT_STATUS_FILTER =
  "TOGGLE_HOMEPAGE_ROLLOUT_STATUS_FILTER";

export const UPDATE_BUSINESS_UNIT = "UPDATE_BUSINESS_UNIT";
