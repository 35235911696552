// 9fbef606107a605d69c0edbcd8029e5d

import { compose, prop } from "ramda";
import {
  UPDATE_EMAIL_LIST_SUBSCRIBER,
  UPDATE_EMAIL_LIST_SUBSCRIBER_FAILURE,
  UPDATE_EMAIL_LIST_SUBSCRIBER_SUCCESS,
  RESET_UPDATE_EMAIL_LIST_SUBSCRIBER,
  RESET_EDITED_EMAIL_LIST_SUBSCRIBERS,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";
import * as reducers from "../..";

const updateSubscriber = () => ({
  payload: {},
  type: UPDATE_EMAIL_LIST_SUBSCRIBER,
});

const updateSubscriberSuccess = (email) => () => ({
  payload: { email },
  type: UPDATE_EMAIL_LIST_SUBSCRIBER_SUCCESS,
});

const updateSubscriberFailure = (error) => ({
  error: true,
  payload: error,
  type: UPDATE_EMAIL_LIST_SUBSCRIBER_FAILURE,
});

const resetUpdateSubscriber = () => ({
  payload: {},
  type: RESET_UPDATE_EMAIL_LIST_SUBSCRIBER,
});

const resetEditedSubscribers = (payload) => ({
  payload,
  type: RESET_EDITED_EMAIL_LIST_SUBSCRIBERS,
});

export const updateSubscriberEffect =
  (email) =>
  (dispatch, getState, { api }) => {
    dispatch(updateSubscriber());

    const accessToken = RD.withDefault("", reducers.getLoggedUser(getState()));
    const editedLists = prop(email)(reducers.getEditedSubscribers(getState()));

    const requestBody = {
      subscribedLists: editedLists,
    };

    api
      .updateEmailListSubscriber(email, requestBody, accessToken)
      .then((res) => res.status !== 200 && Promise.reject())
      .then(compose(dispatch, updateSubscriberSuccess(email)))
      .catch(compose(dispatch, updateSubscriberFailure));
  };

export const resetUpdateSubscriberEffect = (payload) => (dispatch) => {
  dispatch(resetUpdateSubscriber());
  dispatch(resetEditedSubscribers(payload));
};
