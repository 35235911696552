// 9fbef606107a605d69c0edbcd8029e5d

import { compose, map, split, trim } from "ramda";
import {
  IMPORT_EMAIL_LIST_SUBSCRIBERS,
  IMPORT_EMAIL_LIST_SUBSCRIBERS_FAILURE,
  IMPORT_EMAIL_LIST_SUBSCRIBERS_SUCCESS,
  RESET_IMPORT_EMAIL_LIST_SUBSCRIBERS,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";
import * as reducers from "../..";

const processSubscribersForImport = (newSubscribers, selectedLists) =>
  selectedLists.map((listId) => ({
    listId,
    subscribers: compose(map(trim), split(","))(newSubscribers),
  }));

const importSubscribers = () => ({
  payload: {},
  type: IMPORT_EMAIL_LIST_SUBSCRIBERS,
});

const importSubscribersSuccess = (payload) => ({
  payload,
  type: IMPORT_EMAIL_LIST_SUBSCRIBERS_SUCCESS,
});

const importSubscribersFailure = (error) => ({
  error: true,
  payload: error,
  type: IMPORT_EMAIL_LIST_SUBSCRIBERS_FAILURE,
});

const resetImportSubscribers = () => ({
  payload: {},
  type: RESET_IMPORT_EMAIL_LIST_SUBSCRIBERS,
});

export const importSubscribersEffect =
  (newSubscribers, selectedLists) =>
  (dispatch, getState, { api }) => {
    dispatch(importSubscribers());

    const accessToken = RD.withDefault("", reducers.getLoggedUser(getState()));
    const requestBody = processSubscribersForImport(
      newSubscribers,
      selectedLists
    );

    api
      .importEmailListSubscribers(requestBody, accessToken)
      .then((res) => res.status !== 201 && Promise.reject())
      .then(compose(dispatch, importSubscribersSuccess))
      .catch(compose(dispatch, importSubscribersFailure));
  };

export const resetImportSubscribersEffect = () => (dispatch) => {
  dispatch(resetImportSubscribers());
};
