// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import {
  FETCH_EMAIL_LIST_SUBSCRIBERS,
  FETCH_EMAIL_LIST_SUBSCRIBERS_FAILURE,
  FETCH_EMAIL_LIST_SUBSCRIBERS_SUCCESS,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";
import * as reducers from "../..";

const fetchEmailListSubscribers = () => ({
  type: FETCH_EMAIL_LIST_SUBSCRIBERS,
});

const fetchEmailListSubscribersSuccess = (payload) => ({
  type: FETCH_EMAIL_LIST_SUBSCRIBERS_SUCCESS,
  payload,
});

const fetchEmailListSubscribersFailure = (payload) => ({
  type: FETCH_EMAIL_LIST_SUBSCRIBERS_FAILURE,
  error: true,
  payload,
});

export const fetchEmailListSubscribersEffect =
  () =>
  (dispatch, getState, { api }) => {
    const subscribers = reducers.getEmailListSubscribers(getState());
    const accessToken = RD.withDefault("", reducers.getLoggedUser(getState()));

    if (RD.isSuccess(subscribers) || RD.isLoading(subscribers)) {
      return;
    }

    dispatch(fetchEmailListSubscribers());

    api
      .fetchEmailListSubscribers(accessToken)
      .then((res) => (res.status === 200 ? res.json() : Promise.reject()))
      .then(compose(dispatch, fetchEmailListSubscribersSuccess))
      .catch(compose(dispatch, fetchEmailListSubscribersFailure));
  };
