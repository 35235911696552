// 9fbef606107a605d69c0edbcd8029e5d

import React, { useState, useRef, memo } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import {
  Headline,
  THEME,
  Button,
  Text,
  TextAreaField,
  TEXTAREAFIELD_LABEL_TYPES,
} from "@dhl-official/react-ui-library";
import { MESSAGE_DISPLAY_DURATION } from "../../../utils/constants";

// #region Component Styles
const slideIn = keyframes`
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: 0;
    opacity: 1;
  }
`;

const Container = styled.div``;

const Title = styled(Headline).attrs({
  designLevel: 3,
  tag: Headline.H3,
})`
  color: ${({ shouldHighlight }) =>
    shouldHighlight
      ? THEME.getPrimaryColor("400")
      : THEME.getFontColor("regular")};

  animation: ${THEME.getAnimationSpeed("regular")} ease-in-out 0s forwards
    ${slideIn};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReadOnlyTextArea = styled(TextAreaField).attrs({
  id: "export-modal-input",
  isBlock: true,
  variant: {
    placeholder: "List",
    type: TEXTAREAFIELD_LABEL_TYPES.ANIMATED,
  },
})``;

const DelimiterRow = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${THEME.getUnit("regular")};
`;

const DelimiterLabel = styled(Text).attrs({
  isParagraph: true,
})`
  flex: 1;
  margin: 0;
`;

const DelimiterHint = styled(Text).attrs({
  size: Text.SMALL,
})`
  color: ${THEME.getGrayColor("700")};
  display: block;
`;

const DelimiterButton = styled(Button)`
  align-items: center;
  display: inline-flex;
  margin-left: ${THEME.getUnit("regular")};

  span {
    font-size: ${THEME.getFontSize("big")};
  }
`;

const CopyButton = styled(Button).attrs({
  variant: Button.SECONDARY,
})`
  margin-top: ${THEME.getUnit("regular")};
`;
// #endregion

const EmailListExportModal = ({ list }) => {
  const interval = useRef();
  const [isCopied, setIsCopied] = useState(false);
  const [delimiter, setDelimiter] = useState(",");

  const writtenList = list.join(delimiter);

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = writtenList;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    if (!interval.current) {
      setIsCopied(true);
      interval.current = setTimeout(() => {
        setIsCopied(false);
        clearTimeout(interval.current);
        interval.current = null;
      }, MESSAGE_DISPLAY_DURATION);
    }
  };

  return (
    <Container>
      {isCopied ? (
        <Title shouldHighlight key={1}>
          List Copied!
        </Title>
      ) : (
        <Title key={2}>Copy List To Clipboard</Title>
      )}
      <DelimiterRow>
        <DelimiterLabel>
          Choose your delimiter
          <DelimiterHint>
            (Outlook needs semicolons, otherwise commas)
          </DelimiterHint>
        </DelimiterLabel>
        <DelimiterButton
          variant={delimiter === "," ? Button.PRIMARY : Button.SECONDARY}
          onClick={() => setDelimiter(",")}
        >
          ,
        </DelimiterButton>
        <DelimiterButton
          variant={delimiter === ";" ? Button.PRIMARY : Button.SECONDARY}
          onClick={() => setDelimiter(";")}
        >
          ;
        </DelimiterButton>
      </DelimiterRow>
      <Content>
        <ReadOnlyTextArea value={writtenList} />
        <CopyButton onClick={copyToClipboard}>Copy List</CopyButton>
      </Content>
    </Container>
  );
};

EmailListExportModal.propTypes = {
  list: PropTypes.array.isRequired,
};

export default memo(EmailListExportModal);
