// 9fbef606107a605d69c0edbcd8029e5d

import { combineReducers } from "redux";
import deleteSubscriberReducer, * as deleteSubscriber from "./delete-subscriber";
import editedSubscribersReducer, * as editedSubscribers from "./edited-subscribers";
import importSubscribersReducer, * as importSubscribers from "./import-subscribers";
import listsReducer, * as lists from "./lists";
import subscribersReducer, * as subscribers from "./subscribers";
import updateSubscriberReducer, * as updateSubscriber from "./update-subscriber";

export default combineReducers({
  deleteSubscriber: deleteSubscriberReducer,
  editedSubscribers: editedSubscribersReducer,
  importSubscribers: importSubscribersReducer,
  lists: listsReducer,
  subscribers: subscribersReducer,
  updateSubscriber: updateSubscriberReducer,
});

export const getDeleteSubscriber = (state) =>
  deleteSubscriber.get(state.deleteSubscriber);
export const getEditedSubscribers = (state) =>
  editedSubscribers.get(state.editedSubscribers);
export const getImportSubscribers = (state) =>
  importSubscribers.get(state.importSubscribers);
export const getLists = (state) => lists.get(state.lists);
export const getSubscribers = (state) => subscribers.get(state.subscribers);
export const getUpdateSubscriber = (state) =>
  updateSubscriber.get(state.updateSubscriber);
