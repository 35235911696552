// 9fbef606107a605d69c0edbcd8029e5d

import { append, without } from "ramda";
import {
  RESET_EDITED_EMAIL_LIST_SUBSCRIBERS,
  UPDATE_EDITED_EMAIL_LIST_SUBSCRIBERS,
} from "../../../actions";

const updateEditedSubscribers = (payload) => ({
  payload,
  type: UPDATE_EDITED_EMAIL_LIST_SUBSCRIBERS,
});

const resetEditedSubscribers = (payload) => ({
  payload,
  type: RESET_EDITED_EMAIL_LIST_SUBSCRIBERS,
});

export const updateEditedSubscriberEffect = (payload) => (dispatch) => {
  const { email, listId, displayedLists } = payload;

  let updatedLists = displayedLists;
  if (displayedLists.includes(listId)) {
    updatedLists = without([listId], displayedLists);
  } else {
    updatedLists = append(listId, displayedLists);
  }

  dispatch(updateEditedSubscribers({ email, updatedLists }));
};

export const resetEditedSubscriberEffect = (payload) => (dispatch) => {
  dispatch(resetEditedSubscribers(payload));
};
