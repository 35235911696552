import React, { Fragment, lazy } from "react";
import { Switch, Redirect } from "react-router-dom";
import querystring from "querystring";
import { compose, find, propOr } from "ramda";
import { format } from "date-fns";
import AsyncRoute from "../AsyncRoute/container";
import Header from "../Header/container";
import Footer from "../Footer";
import Modals from "../Modals/container";
import {
  APPLICATIONS,
  CONNECTORS_OVERVIEW_SORT_PROPERTIES,
  FS_COUNTRIES,
  FS_COUNTRIES_FSX_PROFILES,
  GAQ_COUNTRIES,
  RELEASE_NOTE_ADMIN_MODE,
  PERMISSIONS,
  ROLLOUT_SORT_PROPERTIES,
  ROUTES,
  SORT_DIRECTIONS,
} from "../../utils/constants";
import BackToTop from "../BackToTop";

const ReleaseTimeline = lazy(() =>
  import(
    /* webpackChunkName: "release-timeline" */ "../ReleaseTimeline/container"
  )
);

const ReleaseNoteFromApi = lazy(() =>
  import(/* webpackChunkName: "release-note-gaq" */ "../ReleaseNotes/container")
);

const AddUpdateReleaseNote = lazy(() =>
  import(
    /* webpackChunkName: "release-note-gaq-add-update" */ "../ReleaseNotes/AddUpdateReleaseNote/container"
  )
);

const RolloutOverview = lazy(() =>
  import(
    /* webpackChunkName: "rollout-overview" */ "../RolloutProgress/RolloutOverview/container"
  )
);

const ProjectOverview = lazy(() =>
  import(
    /* webpackChunkName: "project-overview" */ "../RolloutProgress/ProjectOverview/container"
  )
);

const BrowserMatrix = lazy(() =>
  import(/* webpackChunkName: "browser-matrix" */ "../BrowserMatrix")
);

const Release = lazy(() =>
  import(/* webpackChunkName: "release" */ "../Release/container")
);

const FourOhFour = lazy(() =>
  import(/* webpackChunkName: "four-of-four" */ "../FourOhFour")
);

const FSCountryConfigurations = lazy(() =>
  import(
    /* webpackChunkName: "country-fs" */ "../CountryConfigurations/FrequentShipment/container"
  )
);

const GAQCountryConfigurations = lazy(() =>
  import(
    /* webpackChunkName: "country-gaq" */ "../CountryConfigurations/GetAQuote/container"
  )
);

const CJRolloutProgress = lazy(() =>
  import(
    /* webpackChunkName: "rollout-cj" */ "../RolloutProgress/CustomerJourney/container"
  )
);

const HomepageRolloutProgress = lazy(() =>
  import(
    /* webpackChunkName: "rollout-homepage" */ "../RolloutProgress/Homepage/container"
  )
);

const NewsletterAdministration = lazy(() =>
  import(
    /* webpackChunkName: "newsletter-administration" */ "../NewsletterAdministration/container"
  )
);

const ReleaseNoteAdmin = lazy(() =>
  import(
    /* webpackChunkName: "release-note-gaq-admin" */ "../ReleaseNotes/Admin/container"
  )
);

const ConnectorsOverview = lazy(() =>
  import(
    /* webpackChunkName: "connectors-overview" */ "../ConnectorsOverview/container"
  )
);

const LATEST_UPDATE_DATE = new Date(process.env.LATEST_UPDATE);

const Application = () => (
  <Fragment>
    <Header latestUpdateDate={LATEST_UPDATE_DATE} />

    <Switch>
      <Redirect
        exact
        from="/"
        to={`${ROUTES.CJ_ROLLOUT_OVERVIEW}/${APPLICATIONS.ALL}`}
      />

      <Redirect
        exact
        from={ROUTES.CJ_ROLLOUT_OVERVIEW}
        to={`${ROUTES.CJ_ROLLOUT_OVERVIEW}/${APPLICATIONS.ALL}`}
      />

      <Redirect
        exact
        from={ROUTES.HOMEPAGE_ROLLOUT_OVERVIEW}
        to={`${ROUTES.HOMEPAGE_ROLLOUT_OVERVIEW}/${APPLICATIONS.ALL}`}
      />

      <Redirect
        exact
        from={ROUTES.FS_COUNTRY_CONFIGURATIONS}
        to={`${ROUTES.FS_COUNTRY_CONFIGURATIONS}/DE`}
      />

      <Redirect
        exact
        from={ROUTES.GAQ_COUNTRY_CONFIGURATIONS}
        to={`${ROUTES.GAQ_COUNTRY_CONFIGURATIONS}/AU`}
      />

      <Redirect from="/rollout-overview/:path" to="/rollout-progress/:path" />

      <Redirect
        exact
        from={ROUTES.PROJECT_OVERVIEW}
        to={`${ROUTES.PROJECT_OVERVIEW}/${format(new Date(), "MM-Y")}`}
      />

      <AsyncRoute
        exact
        path={ROUTES.BROWSER_MATRIX}
        component={BrowserMatrix}
      />

      <AsyncRoute exact path={ROUTES.TIMELINE} component={RolloutOverview} />

      <AsyncRoute
        exact
        path={`${ROUTES.CJ_ROLLOUT_OVERVIEW}/:application`}
        render={({ match: { params }, location: { search } }) => {
          const query = querystring.parse(search.substr(1));

          return (
            <CJRolloutProgress
              application={params.application}
              filteredRegion={query.region}
              sortProperty={
                query.sortProperty || ROLLOUT_SORT_PROPERTIES.COUNTRY
              }
              sortDirection={query.sortDirection || SORT_DIRECTIONS.ASC}
            />
          );
        }}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.PROJECT_OVERVIEW}/:view`}
        render={({ match: { params }, location: { search } }) => {
          const query = querystring.parse(search.substr(1));

          return <ProjectOverview detail={query.detail} view={params.view} />;
        }}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.HOMEPAGE_ROLLOUT_OVERVIEW}/:version`}
        render={({ match: { params }, location: { search } }) => {
          const query = querystring.parse(search.substr(1));

          return (
            <HomepageRolloutProgress
              version={params.version}
              filteredRegion={query.region}
              sortProperty={
                query.sortProperty || ROLLOUT_SORT_PROPERTIES.COUNTRY
              }
              sortDirection={query.sortDirection || SORT_DIRECTIONS.ASC}
            />
          );
        }}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.FS_RELEASE_NOTES}/:version?`}
        render={({ match: { params } }) => (
          <ReleaseTimeline
            application={APPLICATIONS.FREQUENT_SHIPMENT}
            version={params.version}
          />
        )}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.FSX_RELEASE_NOTES}/:version?`}
        render={({ match: { params } }) => (
          <ReleaseTimeline
            application={APPLICATIONS.FREQUENT_SHIPMENT_FSX}
            version={params.version}
          />
        )}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.GAQ_RELEASE_NOTES}/:version?`}
        render={({ match: { params } }) => (
          <ReleaseNoteFromApi
            application={APPLICATIONS.GET_A_QUOTE}
            version={params.version}
          />
        )}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.GAQ_RELEASE_NOTES_ADMIN}`}
        requiredUserPermission={PERMISSIONS.WRITE_RELEASE_NOTES}
        render={() => <ReleaseNoteAdmin />}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.GAQ_ADD_RELEASE_NOTE}`}
        requiredUserPermission={PERMISSIONS.WRITE_RELEASE_NOTES}
        render={() => (
          <AddUpdateReleaseNote mode={RELEASE_NOTE_ADMIN_MODE.ADD} />
        )}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.GAQ_UPDATE_RELEASE_NOTE}/:id`}
        requiredUserPermission={PERMISSIONS.WRITE_RELEASE_NOTES}
        render={({ match: { params } }) => (
          <AddUpdateReleaseNote
            id={params.id}
            mode={RELEASE_NOTE_ADMIN_MODE.UPDATE}
          />
        )}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.SHIP_NOW_RELEASE_NOTES}/:version?`}
        render={({ match: { params } }) => (
          <ReleaseTimeline
            application={APPLICATIONS.SHIP_NOW}
            version={params.version}
          />
        )}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.FS_RELEASE}/:version`}
        render={({ match: { params } }) => <Release version={params.version} />}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.FS_COUNTRY_CONFIGURATIONS}/:country`}
        render={({ match: { params }, location: { search } }) => {
          const query = querystring.parse(search.substr(1));

          return FS_COUNTRIES.some(([code]) => code === params.country) ? (
            <FSCountryConfigurations
              country={params.country}
              profile={compose(
                find((el) => el === query.profile),
                propOr([], params.country)
              )(FS_COUNTRIES_FSX_PROFILES)}
            />
          ) : (
            <FourOhFour />
          );
        }}
      />

      <AsyncRoute
        exact
        path={`${ROUTES.GAQ_COUNTRY_CONFIGURATIONS}/:country`}
        render={({ match: { params } }) =>
          GAQ_COUNTRIES.some(([code]) => code === params.country) ? (
            <GAQCountryConfigurations country={params.country} />
          ) : (
            <FourOhFour />
          )
        }
      />

      <AsyncRoute
        exact
        path={ROUTES.NEWSLETTER_ADMINISTRATION}
        component={NewsletterAdministration}
        requiredUserPermission={PERMISSIONS.WRITE_EMAIL_ADMINISTRATION}
      />

      <AsyncRoute
        exact
        path={ROUTES.CONNECTORS_OVERVIEW}
        render={({ location: { search } }) => {
          const query = querystring.parse(search.substr(1));

          return (
            <ConnectorsOverview
              sortProperty={
                query.sortProperty || CONNECTORS_OVERVIEW_SORT_PROPERTIES.DATE
              }
              sortDirection={query.sortDirection || SORT_DIRECTIONS.DESC}
            />
          );
        }}
      />

      <AsyncRoute component={FourOhFour} />
    </Switch>

    <BackToTop />

    <Modals />

    <Footer />
  </Fragment>
);

export default Application;
