// 9fbef606107a605d69c0edbcd8029e5d

import { identity, omit } from "ramda";
import {
  RESET_EDITED_EMAIL_LIST_SUBSCRIBERS,
  UPDATE_EDITED_EMAIL_LIST_SUBSCRIBERS,
} from "../../../actions";

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESET_EDITED_EMAIL_LIST_SUBSCRIBERS:
      return omit([payload], state);

    case UPDATE_EDITED_EMAIL_LIST_SUBSCRIBERS: {
      const { email, updatedLists } = payload;

      return {
        ...state,
        [email]: updatedLists,
      };
    }

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
