// 9fbef606107a605d69c0edbcd8029e5d

import {
  TOGGLE_APPLICATION_ROLLOUT_FILTER,
  TOGGLE_BU_ROLLOUT_FILTER,
} from "../../actions";

export const toggleApplicationRolloutFilter = (payload) => ({
  type: TOGGLE_APPLICATION_ROLLOUT_FILTER,
  payload,
});

export const toggleBusinessUnitRolloutFilter = (payload) => ({
  type: TOGGLE_BU_ROLLOUT_FILTER,
  payload,
});
