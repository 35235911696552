// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import {
  UPDATE_EMAIL_LIST_SUBSCRIBER,
  UPDATE_EMAIL_LIST_SUBSCRIBER_FAILURE,
  UPDATE_EMAIL_LIST_SUBSCRIBER_SUCCESS,
  RESET_UPDATE_EMAIL_LIST_SUBSCRIBER,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";

const INITIAL_STATE = RD.notAsked();

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESET_UPDATE_EMAIL_LIST_SUBSCRIBER:
      return RD.notAsked();

    case UPDATE_EMAIL_LIST_SUBSCRIBER:
      return RD.loading();

    case UPDATE_EMAIL_LIST_SUBSCRIBER_SUCCESS:
      return RD.success(payload);

    case UPDATE_EMAIL_LIST_SUBSCRIBER_FAILURE:
      return RD.error(payload);

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
