import React from "react";
import styled from "styled-components";
import { Modal, THEME } from "@dhl-official/react-ui-library";
import PropTypes from "prop-types";
import { MODALS } from "../../utils/constants";
import EditBusinessUnitModal from "./EditBusinessUnitModal/container";
import EmailListDeleteSubscriberModal from "./EmailListDeleteSubscriber/container";
import DeleteReleaseNoteModal from "./DeleteReleaseNoteModal/container";
import EmailListExportModal from "./EmailListExport";
import Share from "./Share";
import MapRegionModal from "./MapRegion/CustomerJourney";
import HomepageModal from "./MapRegion/Homepage";
import TimelinePhaseDetails from "./TimelinePhaseDetails";
import ProjectOverviewDetails from "./ProjectOverviewDetails";

// #region Component Styles
const StyleModal = styled(Modal)`
  overflow-y: auto;
  padding: calc(${THEME.getUnit("large")} * 2);

  :focus {
    outline-color: transparent;
  }
`;
// #endregion

const getOpenedModal = (openedModal, data, closeModal) => {
  switch (openedModal) {
    case MODALS.SHARE:
      return <Share url={data} />;

    case MODALS.CJ_MAP_REGION:
      return <MapRegionModal rolledOutCountry={data} />;

    case MODALS.HOMEPAGE_MAP_REGION:
      return <HomepageModal rolledOutCountry={data} />;

    case MODALS.TIMELINE_PHASE_DETAILS:
      return <TimelinePhaseDetails phase={data} />;

    case MODALS.PROJECT_OVERVIEW_DETAILS:
      return <ProjectOverviewDetails entry={data} />;

    case MODALS.EDIT_BUSINESS_UNIT:
      return (
        <EditBusinessUnitModal
          identity={data.identity}
          onClose={closeModal}
          options={data.options}
          threshold={data.threshold}
        />
      );

    case MODALS.DELETE_RELEASE_NOTE:
      return <DeleteReleaseNoteModal closeModal={closeModal} data={data} />;

    case MODALS.EMAIL_LIST_DELETE_SUBSCRIBER:
      return (
        <EmailListDeleteSubscriberModal closeModal={closeModal} email={data} />
      );

    case MODALS.EMAIL_LIST_EXPORT:
      return <EmailListExportModal list={data} />;

    default:
      return "";
  }
};

const Modals = ({ closeModal, openedModal, data }) => (
  <StyleModal
    closeButtonAriaLabel="Close"
    contentAriaLabel="Share dialog"
    isOpen={Boolean(openedModal)}
    onClose={closeModal}
  >
    {getOpenedModal(openedModal, data, closeModal)}
  </StyleModal>
);

Modals.propTypes = {
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.any,
  openedModal: PropTypes.string,
};

export default Modals;
