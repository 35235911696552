// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import {
  FETCH_EMAIL_LIST_LISTS,
  FETCH_EMAIL_LIST_LISTS_FAILURE,
  FETCH_EMAIL_LIST_LISTS_SUCCESS,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";
import * as reducers from "../..";

const fetchEmailListLists = () => ({
  type: FETCH_EMAIL_LIST_LISTS,
});

const fetchEmailListListsSuccess = (payload) => ({
  type: FETCH_EMAIL_LIST_LISTS_SUCCESS,
  payload,
});

const fetchEmailListListsFailure = (payload) => ({
  type: FETCH_EMAIL_LIST_LISTS_FAILURE,
  error: true,
  payload,
});

export const fetchEmailListListsEffect =
  () =>
  (dispatch, getState, { api }) => {
    const lists = reducers.getEmailListLists(getState());
    const accessToken = RD.withDefault("", reducers.getLoggedUser(getState()));

    if (RD.isSuccess(lists) || RD.isLoading(lists)) {
      return;
    }

    dispatch(fetchEmailListLists());

    api
      .fetchEmailListLists(accessToken)
      .then((res) => (res.status === 200 ? res.json() : Promise.reject()))
      .then(compose(dispatch, fetchEmailListListsSuccess))
      .catch(compose(dispatch, fetchEmailListListsFailure));
  };
