// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import {
  FETCH_EMAIL_LIST_LISTS,
  FETCH_EMAIL_LIST_LISTS_FAILURE,
  FETCH_EMAIL_LIST_LISTS_SUCCESS,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";

const INITIAL_STATE = RD.notAsked();

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_EMAIL_LIST_LISTS:
      return RD.loading();

    case FETCH_EMAIL_LIST_LISTS_SUCCESS:
      return RD.success(payload);

    case FETCH_EMAIL_LIST_LISTS_FAILURE:
      return RD.error(payload);

    default:
      return state;
  }
};

export default reducer;

export const get = identity;
